import * as serviceWorker from './serviceWorker'
import { loadConfig } from 'settings'

const boostrapApp = async () => {
  await loadConfig()
  await import('./ReactApp')
}

boostrapApp()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
